<template>
  <!-- 登录 -->
  <div class="login">
    <img class="semi-circle" src="@/assets/image/login/Ellipse 1715.png" alt="">
    <!-- 注册、登录 -->
    <div class="box1 main-box">
      <div class="box_con">
        <img class="round" src="@/assets/image/login/Ellipse 1717.png" alt="">
        <!-- state 1页面2弹框  type 1注册 2登录 -->
        <login v-if="true" :state='1' :type='type' @changeType="changeType"></login>
      </div>
    </div>
    <div class="foot">
      <div class="waves">
        <i class="public-i first"></i>
        <i class="public-i second"></i>
      </div>
    </div>
  </div>
</template>

<script>
import login from "@/components/login/login.vue";
export default {
  // 可用组件的哈希表
  components: {
    login,
  },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      type: 2, //1注册 2登录
      loginType: '1', //登录方式 1密码 2短信
      showLoginPop: true, //登录注册弹框      
    }
  },
  // 事件处理器
  methods: {
    // 注册登录切换
    changeType(e) {
      this.type = e
    },
    // 关闭登录弹框
    closeLoginPop() {
      this.showLoginPop = false
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    let type = this.$route.query.type || 2
    this.type = Number(type)
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    $route(){
      let type = this.$route.query.type || 2
      this.type = Number(type)
    }
  }
}
</script>

<style lang="less" scoped>
  * {box-sizing: border-box}
  .ant-input {
    border: 0;
  }
  .ant-input:focus {
    border: 0;
    box-shadow: none;
  }
  .login {
    display: flex;
    align-content: center;
    position: relative;
    min-height: 100vh;
    background-color: #fff; 
    .semi-circle{
      position: absolute;
      top: 0;
      left: -40px;
    }
    // 注册、登录
    .box1 {
      position: relative;
      width: 1400px;
      margin-top: 240px;
      margin-bottom: 118px;
      z-index: 10;
      text-align: center;
      min-height: auto;
      .box_con {
        display: inline-block;
        box-shadow: 4px 4px 40px 0px rgba(0,0,0,0.1);
        position: relative;
        z-index: 10;
        background: #fff;
        border-radius: 8px;
        
        .round{
          position: absolute;
          z-index: 0;
          right: -120px;
          top: -95px;
          width: 203px;
          height: 203px;
          animation: rotate 4s linear infinite;
        }
        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .con_img {
          height: 482px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img {
            width: 500px;
          }
        }
      }
    }

    .foot{
      position: fixed;
      bottom: -2px;
      height: 218px;
      width: 100%;
      z-index: 0;
      .waves{
        height: 100%;
        overflow: hidden;
        position: relative;
        .public-i{
          display: block;
          height: 100%;
          position: absolute;
          width: 4694px;
          z-index: 3;
          left: 0;
        }
        .first{
          background: url('../../assets/image/login/foot-bg1.png') top/cover;
          animation: freames1 20s cubic-bezier(.14,1,.87,.13) infinite alternate;
        }
        .second{
          background: url('../../assets/image/login/foot-bg2.png') top/cover;
          animation: freames2 20s cubic-bezier(.14,1,.87,.13) infinite alternate;
          left: auto;
          right: 0;
        }
        @keyframes freames1 {
          0% {
            transform: translateZ(0)
          }
          100% {
            transform: translate3d(-50%,0,0);
          }
        }
        @keyframes freames2 {
          0% {
            transform: translateZ(0);
          }
          100% {
            transform: translate3d(50%,0,0);
          }
        }
      }
    }
  }
</style>
